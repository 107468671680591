var passwordReset = {

    form: {},

    _init: function() {

        if ( $( '#reset-password-form' ).length != 1 )
            return false;

        this.form = $( '#reset-password-form' );

        this.getUserDataByHash();

    },

    getUserDataByHash: function() {

        var hash = this.findGetParameter( 'hash' ); 
        var email = this.findGetParameter( 'email' ); 
        var self = this;

        if ( typeof hash == 'undefined' ||  hash == '' || typeof email == 'undefined' ||  email == '' )
            return false;

        var url = $( this.form ).attr( 'data-get-user-by-hash' );

        $.get( url, {hash: hash, email: email}).done( function( user ) {
            
            $( self.form ).find( 'input[name=id]' ).attr( 'value' , user.id );
            $( self.form ).find( 'input[name=hash]' ).attr( 'value' , user.hash );
            $( self.form ).find( 'input[name=email]' ).attr( 'value' , user.email );

        });

    },

    findGetParameter :function(parameterName) {

        var result = null, tmp = [];
        var items = location.search.substr(1).split("&");

        for (var index = 0; index < items.length; index++) {

            tmp = items[index].split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);

        }

        return result;
    },

    objectifyForm: function( formArray ) {
        
        var returnArray = {};

        for (var i = 0; i < formArray.length; i++)
            returnArray[formArray[i]['name']] = formArray[i]['value'];
        
        return returnArray;

    }

}

$( document ).ready(function() {
   
    passwordReset._init();

});