$(document).ready(function(){
    // $(".download-application").click(function(event){

    //     event.preventDefault();

    //     $('#download-popup').css('display','flex');

    //     var modal = document.getElementById('download-popup');


    //     window.onclick = function(event) {
    //         if (event.target == modal) {
    //             $('#download-popup').fadeOut('slow');
    //         }
    //     }


        
    // });
});