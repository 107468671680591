var newsletter= {
    email: '',

    init: function() {
        this.registerEvents();
        // this.events();
    },

    registerEvents: function(){
        var self = this;

        $(".newsletter-popup .popup-blend").click( this.hidePopup );

        
        $('.newsletter-popup').click(function( event ) {
            if( $(event.target).is('.newsletter-popup') ) {
                self.hideModal();
            }
        });

        $(document).keyup(function(e) {
            if (e.key === "Escape") { 
                self.hidePopup();
        }
    });
    },

    hidePopup: function(){
        popup = $('.newsletter-popup');
        popup.css('display', 'none');
    },

};

$(document).ready(function () {
    newsletter.init();
});
