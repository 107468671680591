$(document).ready(function(){
    $(".materials-area").click(function(){

        $('body').addClass('no-scroll');

        var plant = $( this ).attr( 'data-id' );
        var pdfLink = $( this ).attr( 'data-pdf-link' );

        var title = $( this ).find( '.materials-item' ).attr('data-title');
        var subtitle = $( this ).find( '.materials-item' ).attr('data-subtitle');
        $(".preloader").fadeIn("fast");

        $.get( '?id=' + plant , function( data ) {
            
            $(".popup-content").html(data);
            $('.popup-wrapper #download-pdf').attr( 'href' , pdfLink );
            $('#popup-text .popup-wrapper .popup-header h3').html(title);
            $('#popup-text .popup-wrapper .popup-header h4').html(subtitle);
            $("#popup-text").fadeIn("slow");

        })
        .always( function () {
            $(".preloader").fadeOut("fast");
        });

    });
    
    $(".close-popup").click(function(){

        $("#popup-text").fadeOut("slow");
        $(".popup-content").html('');
        $('.popup-text .popup-wrapper .popup-header h3').html('');
        $('.popup-text .popup-wrapper .popup-header h4').html('');
        $('body').removeClass('no-scroll');
        
    });
});