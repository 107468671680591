var contact = {

    form: {},
    inputFirstName: {},
    inputLastName: {},
    inputFromName: {},

    _init: function() {

        this.form = $( 'form#contact-form' );
        this.inputFirstName = $( this.form ).find( 'input[name=fromFirstName]' );
        this.inputLastName = $( this.form ).find( 'input[name=fromLastName]' );
        this.inputFromName = $( this.form ).find( 'input[name=fromName]' );

        this.registerEvents();

    },

    registerEvents: function() {

        var self = this;

        $( this.inputFirstName ).on( 'keyup' , function() {
            self.changeFromName();
        });

        $( this.inputLastName ).on( 'keyup' , function() {
            self.changeFromName();
        });

    },

    changeFromName: function() {

        var fromName = $( this.inputFirstName ).val() + " " + $( this.inputLastName ).val();
        $( this.inputFromName ).val( fromName );

    }

};

$( document ).ready(function() {
    
    contact._init();
 
});