var createAccountForm = {

    _init: function() {

        this.registerEvents();

    },

    registerEvents: function() {

        var self = this;

        $( '#accept-user-form' ).submit( function( event ) {

            if( !self.validateForm( $( this ).find( 'input' ) ) ) {
                event.preventDefault();
            } 

        });

    },

    validateForm: function( formData ) {

        var validated = true;
        
        validated = this.validateEmail( formData, 'email' );

        validated = this.validatePassword( formData, 'password' );

        return validated;

    },

    validateEmail: function( formData , name ) {

        var value = this.getParamByName( formData , name );
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (!filter.test(value)){

            $( 'input[name=' + name + ']' ).parent().addClass( 'has-error' );

            return false;

        }
        
        $( 'input[name=' + name + ']' ).parent().removeClass( 'has-error' );

        return true;

    },

    validatePassword: function( formData, name ) {

        var value = this.getParamByName( formData , name );
        var passwordRepeat = this.getParamByName( formData , 'password-repeat' );

        if( value.length <= 6 || value != passwordRepeat )
        {
            $( 'input[name=' + name + ']' ).parent().addClass( 'has-error' );
            $( 'input[name=password-repeat]' ).parent().addClass( 'has-error' );           
            return false;
        }

        $( 'input[name=' + name + ']' ).parent().removeClass( 'has-error' );
        $( 'input[name=password-repeat]' ).parent().removeClass( 'has-error' );

        return true;

    },


    getParamByName: function( data, name ) {

        var param = false;

        data.each(function( index ) {
            
            if( $( this ).name == name )
                param = $(this).value;

        } );

        return param;

    }

};

$( document ).ready( function() {

    createAccountForm._init();

});