$( '.accordion-header' ).click( function() {


    $(this).parent().toggleClass( 'accordion-expand' );
   




});
