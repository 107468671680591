
const EMPTY_PARISH = {
    id: 0,
    parishId: "",
    active: 0,
    added: 0,
    addedDate: "",
    applicationDate: "",
    updateDate: "",
    postponed: 0,
    postponeComment: null,
    additionalInformation: "",
    parishFullName: "",
    parishCode: "",
    city: "",
    postalCode: "",
    street: "",
    streetNumber: "",
    country: "",
    language: 1,
    parishLng: "",
    parishLat: "",
    mapsSelect: 0,
    parishEmail: "",
    parishPhone: "",
    deleted: 0
}

var addHost = {

    getParishUrl: '',
    parishSelect: '',

    parishesSelected: false,

    parishList: [],

    _init: function() {

        this.parishSelect = '#parish-list';
        this.isParishKnown = true;
        this.isParishKnownElement = document.querySelector('fieldset#is-parish-known')
        this.mockupParishId = this.isParishKnownElement.getAttribute('data-mockup-parish-id');
        this.getParishUrl = $('#add-host-form').attr('data-parishes-list-url');
        this.noParish = EMPTY_PARISH
        this.registerEvents();
        this.startSelect2();


        this.loadParishes();

    },

    registerEvents: function() {

        var self = this;

        $( this.parishSelect ).on( 'change' , function( event ) {
            if(!event.target.value){
                self.clearInputs()
            } else {
                self.chooseParish( $( this ).val() );
            }
        });

        const parishSelectWrapper = document.querySelector('div.parish-select')

        this.isParishKnownElement .addEventListener("change", (event) => {
            // result.textContent = `You like ${event.target.value}`;
            this.isParishKnown = event.target.value === 'true'
            if(this.isParishKnown){
                parishSelectWrapper.classList.remove('disabled')
                $(this.parishSelect).val(null).trigger('change');
            } else {
                parishSelectWrapper.classList.add('disabled')
                $(this.parishSelect).val(this.noParish.id).trigger('change');
            }
        });

    },

    setNoParish: function(data){
        this.noParish = data
    },

    startSelect2: function() {

        $( this.parishSelect ).select2();
    },

    loadParishes: function() {
        var self = this;
        var parishId = self.getParameterByName('parishId');

        $.get( this.getParishUrl , function( data ) {
            const mockupParish = data.find((parish) => parish.parishId === self.mockupParishId)
            // console.log(mockupParish, data, self.mockupParishId);
            self.setNoParish(mockupParish)
            self.parishList = data;
            self.appendOptions();

            if( parishId ) {
                $(this.parishSelect).val(parishId).trigger('change');
            }
        });

    },

    appendOptions: function() {

        var self = this;

        for ( var i in this.parishList ) {

            let parish = this.parishList[i];

            $( self.parishSelect ).append( '<option value="' + parish.id + '">' + parish.parishFullName +  ' (' + parish.city + ')</option>')

        }

    },

    chooseParish: function( parishId ) {
        var parish = this.getParishFromList( parishId );

        if( !parish ){
            return false;
        }

        this.fillInputs( parish );

    },

    getParishFromList: function( parishId ) {

        var parish = false;

        this.parishList.forEach(function( parishItem ) {

            if( parishItem.id == parishId )
            parish = parishItem;

        }, this);

        return parish;

    },

    clearInputs: function(){

        $( 'input[name="parish[parishFullName]"]' ).val('');
        $( 'input[name="parish[country]"]' ).val('');
        $( 'input[name="parish[city]"]' ).val('');
        $( 'input[name="parish[street]"]' ).val('');
        $( 'input[name="parish[streetNumber]"]' ).val('');
        $( 'input[name="parish[postalCode]"]' ).val('');
        $( 'input[name="parish[parishEmail]"]' ).val('');
        $( 'input[name="parish[parishPhone]"]' ).val('');
    },

    fillInputs: function( parish ) {
        $( 'input[name="parish[parishFullName]"]' ).val( parish.parishFullName );
        $( 'input[name="parish[country]"]' ).val( parish.country );
        $( 'input[name="parish[city]"]' ).val( parish.city );
        $( 'input[name="parish[street]"]' ).val( parish.street );
        $( 'input[name="parish[streetNumber]"]' ).val( parish.streetNumber );
        $( 'input[name="parish[postalCode]"]' ).val( parish.postalCode );
        $( 'input[name="parish[parishEmail]"]' ).val( parish.parishEmail );
        $( 'input[name="parish[parishPhone]"]' ).val( parish.parishPhone );
    },


    getParameterByName: function(name, url) {

        if (!url)
            url = window.location.href;

        name = name.replace(/[\[\]]/g, "\\$&");

        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);

        if (!results)
            return null;

        if (!results[2])
            return '';

        return decodeURIComponent(results[2].replace(/\+/g, " "));

    }

};

$( document ).ready(function() {

    addHost._init();

});
