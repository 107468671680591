$(document).ready(function(){
    
    $( '#arrow-tabs-manu' ).click( function(){
        
        $( '.container > .nav' ).toggleClass( 'open-tabs-menu' );  

    });

});

