function recaptchaSuccessful() {
    let recaptchaValid;

    const recaptchaElement =  $(".g-recaptcha");
    recaptchaValid = recaptchaElement.attr("data-test", true);
}

var Panels = {

    stage1: {},
    stage2: {},

    circle1: {},
    circle2: {},

    nextSelector: '#next[type="button"]',
    backSelector: '#back',

    submitSelector: '#next[type="submit"]',

    form: 'form.validate-me',

    currentStage: 1,

    _init: function() {

        this.stage1 = $( '.stage-1' );
        this.stage2 = $( '.stage-2' );

        this.circle1 = $( '#circle-1' );
        this.circle2 = $( '#circle-2' );

        this.registerEvents();

    },

    registerEvents: function() {        

        var self = this;

        $( this.backSelector ).click(function( e ){

            e.preventDefault();

            self.showFirstStage();
        
        });
        
        $( this.nextSelector ).click(function( e ){

            e.preventDefault();

            self.showLastStage();

        });

        $( this.circle1 ).click( function() {

            $( self.backSelector ).click();

        });

        $( this.circle2 ).click( function() {

            $( self.nextSelector ).click();

        });

        $( this.submitSelector ).click( function ( e ) {

            e.preventDefault();
            if ( self.isStageValid( $( self.form ) )  ) {
                const recaptchaElement = this.form.querySelectorAll('.g-recaptcha');
                const formHasRecaptcha = recaptchaElement.length !== 0;
                if(formHasRecaptcha) {
                    const isRecaptchaOk = $(".g-recaptcha").attr("data-test") === "true";
                    if(isRecaptchaOk) {
                        $( self.form ).submit();
                    } else {
                        console.log('recaptcha not valid')
                    }
                } else {
                    $( self.form ).submit();
                }

            }

        });

    },

    showFirstStage: function() {

        var self = this;
        this.currentStage = 1;

        $( this.stage2 ).fadeOut( 'fast' , function() {

            $( self.stage1 ).fadeIn();
            self.animateCircles();       

        });       

    },

    showLastStage: function() {

        var self = this;
        var validated = true;
        

        const stageIsValid = this.isStageValid( this.stage1 );

        if ( !stageIsValid )
            return false;

        this.currentStage = 2;
        
        $( this.stage1 ).fadeOut( 'fast' , function() {

            $( self.stage2 ).fadeIn();

            self.stage2.find( 'input, select' ).removeClass( 'not-valid' );
            self.animateCircles();    

        });

    },

    animateCircles: function() {

        if( this.currentStage == 2 ) {
            $( this.circle2 ).addClass( 'active' ); 
            $( this.circle1 ).removeClass( 'active' );
        } else {
            $( this.circle1 ).addClass( 'active' ); 
            $( this.circle2 ).removeClass( 'active' );
        }

    },

    isStageValid: function ( container ) {

        const self = this;

        const elements = container.find( '[required], .required' );

        let validated = 0;

        elements.each( function () {

            let isValid = self.validateData( $( this ) );

            $( this ).change( function () {

                self.validateData( $( this ) );

            } );

            if ( isValid )
                validated++;

        });

        if ( validated == elements.length )
            return true;

        return false;

    },

    validateData: function( element ) {

        element.removeClass( 'not-valid' );

        const type = element.attr( 'type' );

        switch ( type ) {

            case "text":

                if ( element.val() != '' )
                    return true;

            break;

            case "checkbox":

                if ( element.prop( 'checked' ) )
                    return true;

                element.closest( 'label' ).find( 'span' ).addClass( 'not-valid' );

            break;

            case "email":

                if ( element.val().match( /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,253}[a-zA-Z0-9])?)*$/ ) )
                    return true;

            break;

            case "password":

                if ( element.hasClass( 'pass' ) && element.val() != '' && element.val().length > 5 )
                    return true;
                else if ( element.hasClass( 'pass-repeat' ) && element.val() == element.closest( 'form' ).find( '.pass' ).val() )
                    return true;

            break;

        }
        
        element.addClass( 'not-valid' );

        return false;

    }

};

$(document).ready(function(){
    
    Panels._init();

});