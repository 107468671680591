var sendEmailPopup = {

    popup: {},
    popupVisible: false,

    _init: function() {

        this.popup = $( '#send-mail-popup' );

        this.registerEvents();

    },

    registerEvents: function() {

        var self = this;

        $( '.show-send-email-popup' ).click( function() {

            self.showSendEmailPopup();

        } );

        $( this.popup ).find( '.close-popup' ).click( function() {

            self.hideSendEmailPopup();

        } );

        $( this.popup ).find( 'form' ).submit( function( event ) {

            self.submitForm( event );

        });

    },

    submitForm: function( event ) {

        if( this.validateInputs() )
            return true;

        event.preventDefault();
        $( ".nano-content" ).animate({ scrollTop: 0 }, "fast");

    },

    validateInputs: function() {

        var self = this;
        var validated = true;

        $( this.popup ).find( 'input' ).each( function( input ) {

            if( !validated )
                return false;
            
            if( $( this ).attr( 'type' ) == 'email' )
                validated = self.validateEmail( this );
            else
                validated = self.validateText( this );

        } );

        return validated;

    },

    validateEmail: function( input ) {

        var value = $( input ).val();
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if( !regex.test(value) )
        {
            $( input ).parent().addClass( 'has-error' );
            return false;
        }
        else
        {
            $( input ).parent().removeClass( 'has-error' );
            return true;
        }

    },

    validateText: function( input ) {

        var value = $( input ).val();

        if( $.trim( value ) == '' )
        {
            $( input ).parent().addClass( 'has-error' );
            return false;
        }
        else
        {
            $( input ).parent().removeClass( 'has-error' );
            return true;
        }

    },

    showSendEmailPopup: function() {

        this.popupVisible = true;
        $( this.popup ).fadeIn();
        $('body').addClass('no-scroll');

    },

    hideSendEmailPopup: function() {

        this.popupVisible = false;
        $( this.popup ).fadeOut();
        $('body').removeClass('no-scroll');
    },

    toggleSendEmailPopup: function() {

        this.popupVisible ? this.hideSendEmailPopup() : this.showSendEmailPopup();

    }



};

$(document).ready(function(){
    
    sendEmailPopup._init();

});