var home = {

    _init: function() {

        this.registerEvents();
        this.startSlider();

    },

    registerEvents: function() {

        $('body').on( 'mouseover', '.frames', function() {
            
            $(this).closest('.item').addClass('hovered');

        });

        $('body').on( 'mouseleave', '.frames', function() {
            
            $(this).closest('.item').removeClass('hovered');

        });




    },

    startSlider: function() {


        var slider = $('.slider');

        if (slider.length != 1)
            return false;

        $(slider).slick({
            autoplay: true,
            autoplaySpeed: 5000,
            infinite: true,
            dots: true
        });

    }
};
$(document).ready(function() {
  
    home._init();

});