$( document ).ready( function () {

    $( '#accept-cookies' ).click( function () {

        var name = 'acceptCookie';
        var value = true;

        var date = new Date();
        date.setTime(date.getTime() + ( 365 * 24 * 60 * 60 * 1000));

        var expires = "; expires=" + date.toGMTString();

        document.cookie = name + "=" + value + expires + "; path=/";

        $( '#cookies-statement' ).remove();

    });

} );