function initMap1() {

    var mapForumPlaceLat = parseFloat($('#cords-forum-place').attr('data-map-lat'));
    var mapForumPlaceLng = parseFloat($('#cords-forum-place').attr('data-map-lng'));

    if (window.matchMedia("(min-width: 300px)").matches) {
        startCords = [(mapForumPlaceLat + 0.1), mapForumPlaceLng];
    }
    if (window.matchMedia("(min-width: 992px)").matches) {
            startCords = [mapForumPlaceLat, (mapForumPlaceLng - 0.14)];
    }
    
    var options = {
        center: {lat: startCords[0], lng: startCords[1]},
        zoom: 10
    }

    google.maps.event.addDomListener(window, 'resize', function(){
        var cordsMobile = [(mapForumPlaceLat + 0.1) , mapForumPlaceLng];
        var cordsBigScreen = [mapForumPlaceLat, (mapForumPlaceLng - 0.14)];

        if (window.matchMedia("(min-width: 992px)").matches) {
            mapElement.setCenter(new google.maps.LatLng(cordsBigScreen[0], cordsBigScreen[1] ));
        } else {
            mapElement.setCenter(new google.maps.LatLng(cordsMobile[0],cordsMobile[1]));
        }
    })
    
    var siteUrl = $('#driving-directions').attr('data-root-url');

    var mapContainer = document.getElementById('driving-directions');
    
    if ( mapContainer ) {
        var mapElement = new google.maps.Map(document.getElementById('driving-directions'), options);
        var marker = new google.maps.Marker({
            position: {lat: mapForumPlaceLat, lng: (mapForumPlaceLng)},
            map:mapElement,
            icon: siteUrl + '/dist/img/marker.png'
        });
        console.log(marker)
        mapElement.mapTypes.set( 'styled_map' , map.getMapStyle() );
        mapElement.setMapTypeId( 'styled_map' );  
        
        // var infowindow = new google.maps.InfoWindow({
        //     content: '<h1>hehehe</h1>'
        // });

        marker.addListener('click', function() {
            // if( self.activeWindow )
            //     self.activeWindow.close();
            // alert('aaa')
            infowindow.open(mapElement, marker);
            // self.activeWindow = infowindow; 
        });
    }

}

$(document).ready(function () {
    if( $('#driving-directions').length !== 1 ) {
        return
    }
    var mapScript = document.createElement('script');
    mapScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAAnN5Uleg968EgKgwP2dcCxg3QbzK2oSQ&callback=initMap1';
    document.head.appendChild( mapScript );  
    
    
});




