var howItWorks = {

    _init: function() {

        this.registerEvents();

    },

    registerEvents: function() {

        $('#subpage-how #points a').click( function(event){
            
            event.preventDefault();

            var offsetTop = $($.attr(this, 'href')).offset().top;
            
            $('html,body').animate({
                scrollTop: offsetTop
            }, 800);
            
        });

    }

};



$(document).ready(function(){    
    howItWorks._init();    
});