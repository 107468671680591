var main = {

    _init: function() {

        this.registerEvents();
        this.transformSVGToInline();
        this.setNavMainMenuBoldWidth();
        this.startJquerySelectMenu();

    },

    startJquerySelectMenu: function() {

        $( '.selectmenu' ).selectmenu();

    },

    registerEvents: function() {

        $( '.mobile-menu-toggler' ).click( function(){

            $( 'header' ).toggleClass( 'menu-open' );
        
        });

        $( 'nav ul > li > a' ).click( function() {


            var parent = $( this ).parent();
            var submenu = $( parent ).find( '.submenu' );
            var hasSubmenu = $( submenu ).length > 0;

            if( hasSubmenu )
            {

                $( 'nav ul > li.submenu-visible' ).not( $( parent ) ).removeClass( 'submenu-visible' );
                $( parent ).toggleClass( 'submenu-visible' );
                // $( submenu ).addClass( 'pullDown' );
                
            }

        });

        $( window ).click( function( event ) {

            var submenuVisible = $( '.submenu-visible' );

            if( submenuVisible.length == 0 )
                return true;

            if( !$( event.target ).closest( '.submenu-visible' ).length )
                $( submenuVisible ).removeClass( 'submenu-visible' );

        });

        $( '.language-change' ).on( "selectmenuchange", function( event, ui ){
            const url = $(this).find( ':selected' ).attr( 'data-url' );
            const val = $(this).find( ':selected' ).val();
            window.localStorage.setItem('languageSet', "true" );

            window.location.href = url;

        });

        $( 'nav ul > li > .submenu .close-ico' ).click( function() {

            $( this ).closest( 'li' ).eq( 0 ).removeClass( 'submenu-visible' );

        });

        $(document).keyup(function(e) {
            
            if (e.keyCode == 27)
            $( 'nav ul > li.submenu-visible' ).removeClass( 'submenu-visible' );

        });

        $( '#flash-message-popup button' ).click( function() {

            $( '#flash-message-popup' ).fadeOut();

        });        

        $( ".home-select" ).selectmenu({
            classes: {
                "ui-selectmenu-open": "select-background",
                "selectmenu-button-closed": "select-button"
            }
        });

        $( ".footer-select" ).selectmenu({
            classes: {
                "ui-selectmenu-open": "select-open"
            }
        });

    },

    setNavMainMenuBoldWidth: function() {

        $( 'nav ul.main-menu > li, nav ul.actions-menu > li.jump' ).each( function() {

            $( this ).css( 'width' , $( this ).width() + 5 );

        });

    },

    transformSVGToInline: function() {

        $('img.svg').each(function() {

            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');

            $.get(imgURL, function(data) {

                var $svg = $(data).find('svg');

                if (typeof imgID !== 'undefined')
                    $svg = $svg.attr('id', imgID);

                if (typeof imgClass !== 'undefined')
                    $svg = $svg.attr('class', imgClass + ' replaced-svg');

                $svg = $svg.removeAttr('xmlns:a');
                $img.replaceWith($svg);

            }, 'xml');

        });

    }

};

$(document).ready(function(){

    main._init();
   
});