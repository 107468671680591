var helloasso = {

    helloassoIframe: '<iframe id="haWidget" src="https://www.donnerenligne.fr/newpastoral/faire-un-don/2" style="width:100%;height:750px;min-height:435px;max-height: 100%;border:none;" onload="window.scroll(0, document.querySelector(\'iframe\').offsetTop)"></iframe><div style="width:100%;text-align:center;">Propulsé par <a href="https://www.helloasso.com" rel="nofollow">HelloAsso</a></div>',

    _init: function () {

        this.addListeners();

        if ( window.showPayment == 'helloasso' )
            this.showPopup();

    },

    addListeners: function () {

        this.addHideListener();
        this.addShowListener();

    },

    addHideListener: function () {

        $( '#helloasso-popup .close' ).click( function () {
            
            $( '#helloasso-popup' ).removeClass( 'active' );

        } );

    },

    addShowListener: function () {

        var self = this;

        $( '.helloasso' ).click( function ( e ) {

            e.preventDefault();

            self.showPopup();

        } );

    },

    showPopup: function () {

        $( '#helloasso-popup .payment-wrapper > div' ).html( this.helloassoIframe );
            
        $( '#helloasso-popup' ).addClass( 'active' );

    }

};


$( document ).ready( function () {

    helloasso._init();

} );