var googleMapsAutocomplete = {

    lat: '',
    lng: '',
    street: '',
    streetNumber: '',
    country: '',
    postalCode: '',
    city: '',

    _init: function() {

        this.registerEvents();

    },

    registerEvents: function() {

        var self = this;

        if ($('#googlePlaceAutocomplete').length > 0) {
	        this.registerAutocomplete($('#googlePlaceAutocomplete')[0]);
        }

        $('#complete-address-form').click(function() {

            self.completeForm();

        });

    },

    completeForm: function() {
        $('input[name=parishLat]').val(this.lat)
        $('input[name=parishLng]').val(this.lng);

    },

    registerAutocomplete: function(searchInput) {
        var self = this;

        var autocomplete = new google.maps.places.Autocomplete(searchInput);
        autocomplete.addListener('place_changed', function() {

            var place = autocomplete.getPlace();

            self.lat = place.geometry.location.lat();
            self.lng = place.geometry.location.lng();
            self.completeForm();

        });

    }

};

function initMapAutocomplete() {
	googleMapsAutocomplete._init();
}

$( document).ready(function () {
	if( $('#add-parish-form-1').length !== 1 ) {
		return
	}
	var mapScript = document.createElement('script');
	mapScript.src = 'https://maps.googleapis.com/maps/api/js?v=3.27&libraries=places&key=AIzaSyAAnN5Uleg968EgKgwP2dcCxg3QbzK2oSQ&callback=initMapAutocomplete';
	document.head.appendChild( mapScript );
});
