var payu = {

    _init: function () {

        $('#payU-popup > div').overlayScrollbars({ });
        this.addListeners();

        if ( window.showPayment == 'payU' )
            this.showPopup();

    },

    addListeners: function () {

        this.addHideListener();
        this.addShowListener();
        this.addAmountListener();
        this.addSubmitListener();

    },

    addHideListener: function () {

        $( '#payU-popup .close' ).click( function () {
            
            $( '#payU-popup, .other-amount' ).removeClass( 'active' );

        } );

    },

    addShowListener: function () {

        var self = this;

        $( '.payu' ).click( function ( e ) {

            e.preventDefault();

            self.showPopup();

        } );

    },

    showPopup: function () {
            
        this.showLoader();

        $( '#payU-popup' ).addClass( 'active' );

        $( '#payU-popup .userIp' ).val('');

        $( '#payU-popup input[name="amount"]' ).prop('checked', false);
        $( '#payU-popup input[name="amount"][value=20]' ).prop('checked', true);

        $( '#payU-popup input[name="other-ammount"]' ).val('1');
        $( '#payU-popup input[name="name"]' ).val('');
        $( '#payU-popup input[name="last-name"]' ).val('');
        $( '#payU-popup input[name="email"]' ).val('');
        $( '#payU-popup input[name="phone"]' ).val('');

        $('#payU-popup input[name="arg1"]').prop('checked', false);
        $('#payU-popup input[name="arg2"]').prop('checked', false);
        
        this.getIp();

    },

    getIp: function () {

        var self = this;

        $.getJSON("https://api.ipify.org/?format=json", function(e) {

            $( '#payU-popup .userIp' ).val( e.ip );
            self.hideLoader();

        });

    },

    addAmountListener: function () {
        
        $( 'input[name="amount"]').change( function () {

            var value = $( this ).val();

            if ( value == 'other' )
                $( '.other-amount' ).addClass( 'active' );
            else
                $( '.other-amount' ).removeClass( 'active' ).find('input').val( '1' );

        });

    },

    addSubmitListener: function () {

        var self = this;

        $( '#payU' ).submit( function ( e ) {
    
            e.preventDefault();

            var data = $( this ).serialize();

            var address = $( this ).attr( 'action' );

            self.showLoader();

            self.submitData( data, address );

        } );

    },

    showLoader: function () {

        $( '.payU-loader' ).addClass( 'active' );
        $( '#payU' ).hide();
        $( '#payU-popup .alert' ).hide();

    },

    hideLoader: function () {

        $( '.payU-loader' ).removeClass( 'active' );
        $( '#payU' ).show();
        $( '#payU-popup .alert' ).hide();

    },
    
    showWarning: function () {

        $( '.payU-loader' ).removeClass( 'active' );
        $( '#payU' ).hide();
        $( '#payU-popup .alert' ).show();

    },

    submitData: function ( data, address ) {

        var self = this;

        $.post( address, data)
            .done( function ( data ) {
                window.location.replace( data.url );
            })
            .fail( function () {
                self.showWarning();
            });

    }
    
};

$( document ).ready( function () {

    payu._init();

});