

var map = {

    parishesUrl: '',

    map: {},
    markers: [],
    parishes: [],
    googleMapsAutocompleteInput: false,
    activeWindow: false,

    _init: function() {
        this.initMap();
        this.registerEvents();
    },

    registerEvents: function() {

        var self = this;
        
        if ($('#search-by-city-input').length > 0)
            this.registerAutocomplete($('#search-by-city-input')[0]);

        $( '#search-by-city' ).click( function( event ) {

            event.preventDefault();

            var place = self.googleMapsAutocompleteInput.getPlace();

            if( typeof place !== 'undefined' ) 
                self.centerMapOnPlace( place );

        });

    },

    centerMapOnPlace: function( place  ) {
        
        if (place.geometry.viewport) {
            this.map.fitBounds(place.geometry.viewport);
        } else {
            this.map.setCenter(place.geometry.location);
            this.map.setZoom(17);
        }

    },

    initMap: function() {

        var startLat = parseFloat( $('#location').attr('data-lat') ); 
        var startLng = parseFloat( $('#location').attr('data-lng') );

        this.map = new google.maps.Map(document.getElementById('map'), {
            zoom: 6,
            center: {lat: startLat, lng: startLng},
            gestureHandling: 'cooperative',
            scrollwheel: false,
            navigationControl: false,
            mapTypeControl: false,
            scaleControl: false,
            draggable: true  
        });

        this.loadParishes();    

        this.map.mapTypes.set( 'styled_map' , this.getMapStyle() );
        this.map.setMapTypeId( 'styled_map' );

    },

    loadParishes: function() {

        var self = this;

        var url = $( '#map' ).attr('data-parishes-list-url') + '&mapsSelect=1';
        
        $.get( url , {} ).done(function( parishes ) {

            self.parishes = parishes;

            self.showParishesMarkers( self.parishes );

        });

    },

    showParishesMarkers: function( parishes ) {

        var self = this;            
        var currentLanguage = $( '#map' ).attr('data-current-language');
        var languagesObj = {'pl': 1, 'fr': 2};
        var lang = languagesObj[currentLanguage]; 
        var markerFiltrationArea = document.querySelector('.form-wrapper');
        var inputs = $(markerFiltrationArea).find('input');
        for (var i = 0; i < parishes.length; i++) {
            var parish = parishes[i];
            parish.isActive = parish.language === lang;
            parish.isMarker = parseFloat(parish.parishLat) && parseFloat(parish.parishLng);
            if( parish.isMarker ) {
                parish.marker = self.setMarker( parish );
            }
        }

        $(inputs).click( function ( event ) {
            var inputValue = event.target.value
            for (var i = 0; i < parishes.length; i++) {
                var parish = parishes[i];
                parish.isActive = parish.language == lang;
                var isParishVisible = inputValue == 0 || inputValue == parish.language;  
                if( parish.isMarker ) { 
                    if( isParishVisible && parseFloat(parish.parishLat) && parseFloat(parish.parishLng) ) {
                        parish.marker.setMap(self.map);                        
                    } else {
                        parish.marker.setMap(null);
                    }
                    
                }
            }
        });
    },
    setMarker: function( parish ) {

        var self = this;
        
        var lat = parseFloat( parish.parishLat );
        var lng = parseFloat( parish.parishLng );
        
        var infowindow = new google.maps.InfoWindow({
            content: self.getParishPopup( parish )
        });

        var marker = new google.maps.Marker({
            position: { lat: lat, lng: lng },
            map: self.map,
            title: parish.parishFullNam
        });
        
        var baseUrl = $('#location').attr('data-root-url');
        var icon = parish.isActive ? baseUrl + '/dist/img/marker.png' : baseUrl + '/dist/img/marker-notactive.png'
        marker.setIcon( icon );

        if( parish.isActive ) {
            marker.addListener('click', function() {

                if( self.activeWindow )
                    self.activeWindow.close();

                infowindow.open(map, marker);

                self.activeWindow = infowindow; 

            });
        }

        return marker;       

    },


    registerAutocomplete: function(searchInput) {
        
        var self = this;
        this.googleMapsAutocompleteInput = new google.maps.places.Autocomplete(searchInput);

    },

    getParishPopup: function( parish ) {

        var joinUrl = $('#location').attr('data-join-url') + '?parishId=' + parish.id;

        var addressTranslate = $('.search-window' ).attr( 'data-translate-address' );
        var emailTranslate = $('.search-window' ).attr( 'data-translate-email' );
        var phoneTranslate = $('.search-window' ).attr( 'data-translate-parish' );
        var joinTranslate = $('.search-window' ).attr( 'data-translate-join' );

        var contentString = '<div id="content" class="styl-map">'+
            '<div id="siteNotice">'+
            '</div>'+

            '<h6 id="firstHeading" class="firstHeading">' +  parish.city +  ' - ' + parish.parishFullName + '</h6>'+
            
            '<div id="bodyContent">'+

            '<p> <b> ' + addressTranslate + ': </b>' +  parish.street + ' ' + parish.city + ' ' + parish.postalCode + '</p>' +

            '<p> <b> ' + emailTranslate + ': </b>' +  parish.parishEmail + '</p>' +

            '<p> <b> ' + phoneTranslate + ': </b>' +  parish.parishPhone + '</p>' +                        

            '<p><a class="btn btn-next" href="' + joinUrl + '">'+
            joinTranslate + ' </p>'+
            '</div>'+
            '</div>';

        return contentString;

    },

    getMapStyle: function() {

        return new google.maps.StyledMapType( [
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#404040"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative.country",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "lightness": "60"
                    }
                ]
            },
            {
                "featureType": "administrative.country",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative.country",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "administrative.province",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "saturation": "0"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#707070"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "landscape.man_made",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": "-90"
                    },
                    {
                        "lightness": "40"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "lightness": "-25"
                    },
                    {
                        "saturation": "-100"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "saturation": "-100"
                    },
                    {
                        "lightness": "50"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "poi.business",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.government",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": "-100"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#e8e8e8"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "lightness": "0"
                    },
                    {
                        "color": "#e8e8e8"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "saturation": "-100"
                    },
                    {
                        "lightness": "-30"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "lightness": "0"
                    },
                    {
                        "color": "#707070"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway.controlled_access",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#f0f0f0"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#f0f0f0"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#303030"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#a3c8ec"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#2277cc"
                    }
                ]
            }
        ], {name: 'NewPastoral Map Style'});

    }

};

function initMap() {
    map._init();
    // initMapAutocomplete();
}

$( document).ready(function () {
    if( $('#map').length !== 1 ) {
        return
    }
    var mapScript = document.createElement('script');
    mapScript.src = 'https://maps.googleapis.com/maps/api/js?v=3.27&libraries=places&key=AIzaSyAAnN5Uleg968EgKgwP2dcCxg3QbzK2oSQ&callback=initMap';
    document.head.appendChild( mapScript );
});