var checkEmail = {

    elementToCheck: [],
    form: [],

    _init : function () {

        this.elementToCheck = $( 'input[data-check-email="checkEmail"]' );

        if ( this.elementToCheck.length < 1 )
            return false;

        this.form = this.elementToCheck.closest( 'form' );

        this.startChecking();

    },

    startChecking: function () {

        const self = this;

        this.form.on( 'submit' ,function ( e ) {

            e.preventDefault();

            self.isEmailUnique( this );

        })

    },

    isEmailUnique: function( form ) {

        const url = this.form.attr( 'data-check-email' );
        const email = this.elementToCheck.val();

        const self = this;

        $.ajax({

            'url': url,
            data: { email: email },
            async: false

        })
        .done( function ( data ) {
            
            if ( data ) {

                self.elementToCheck.closest( '.form-group' ).removeClass( 'has-error' );
                form.submit();

            }
            else
                self.elementToCheck.closest( '.form-group' ).addClass( 'has-error' );

        })
        .fail( function () {
            return false;
        });

    }

}

$( document ).ready(function() {
   
    checkEmail._init();

});