var gallery = {

    gallerySelector: '.carousel-slider',

    _init: function() {

        this.registerEvents();

    },

    registerEvents: function() {

        var galleryLinks = $(this.gallerySelector);

        if (!galleryLinks)
            return false;

        $(galleryLinks).on('click', '.image-item a', function(event) {

            event.preventDefault();

            event = event || window.event;
            var link = $( this ).attr( 'href' );
            var links = $( galleryLinks ).find( '.image-item a');

            var selectedIndex = 0;
            links.each( function ( index ) {
                if ( $( this ).attr( 'href' ) == link )
                    selectedIndex = index;
            });

            var options = {
                index: selectedIndex,
                event: event
            };

            blueimp.Gallery(links, options);

        });

    }

};

$(document).ready(function() {

    gallery._init();

});