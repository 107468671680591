var sly = {

    _init: function() {

        const body = $('body');
        const element = body.find('.frame');
        var timer;
        var self = this;

        // if( element.length && $(window).width() < 2025) {

        //     // this.setMobileSlyScroll(element);

        //     // this.timer = setTimeout(function () {
        //     //     self.setCarouselMargin();
        //     // },500);


        // } else {


        // }
        this.setSlyScroll(element);

    },

    setSlyScroll(element){

        jQuery(function ($) {
            // 'use strict';

            document.getElementsByTagName('html')[0].className += ' ' +
            (~window.navigator.userAgent.indexOf('MSIE') ? 'ie' : 'no-ie');

            var $body = $('body');
            var $frame = element; window.frr = $frame;
            var sly = new Sly($frame, {
                horizontal: 1,
                itemNav: 'basic',
                activateMiddle: 1,
                smart: 1,
                mouseDragging: 1,
                touchDragging: 1,
                startAt: 10,
                scrollBar:$body.find('.scrollbar'),
                scrollBy: 0,
                pagesBar:$body.find('.pages'),
                speed: 200,
                moveBy: 768,
                elasticBounds: 1,
                dragHandle: 1,
                dynamicHandle: 1,
                clickBar: 1,

            }).init();
            $(window).on('resize', function () { $frame.sly('reload'); });
        });
    },

    setMobileSlyScroll(element){

        jQuery(function ($) {
            'use strict';
            var $body = $('body');
            var $frame = element; window.frr = $frame;
            var sly = new Sly($frame, {
                horizontal: 1,
                itemNav: 'forceCentered',
                activateMiddle: 1,
                smart: 1,
                activeClass: 'active',
                mouseDragging: 1,
                touchDragging: 1,
                startAt: 10,
                scrollBar:$body.find('.scrollbar'),
                scrollBy: 0,
                pagesBar:$body.find('.pages'),
                activatePageOn: 'click',
                speed: 200,
                moveBy: 768,
                elasticBounds: 1,
                dragHandle: 1,
                dynamicHandle: 1,
                clickBar: 1,

            }).init();

        });



    },

    setCarouselMargin(){

        const slider = $('body').find('.carousel-slider');
        const matrix = $(slider).css('transform');
        const string = matrix.replace('matrix', '').replace('(','').replace(')','');

        const transformX = JSON.parse("[" + string + "]");

        const margins = [
            {width: 1025, padding: 50},
            {width: 769, padding: 35},
            {width: 415, padding: 20},
            {width: 0, padding: 0},
        ]
        for (let index = 0; index < margins.length; index++) {

            if( $(window).width() < margins[index].width && $(window).width() > margins[index + 1].width  ) {

                $(slider).css('margin-left', -(transformX[4]) + margins[index].padding );

            }

        }

    }

}


$(document).ready(function() {

    sly._init();

});
