var filmPopup = {

    _init: function() {

        this.registerEvents();

    },

    registerEvents: function() {

        var self = this;

        $('a').click( function() {

            document.activeElement.blur(); 
            
        });

        $(document).keyup(function(e) {

            if (e.keyCode == 27)
                self.hidePopup(); 

        });

        $('#popup-window').click( function(e) {
            
            if($(e.target).closest('.video').length != 0) 
                return false;

            self.hidePopup();

        });

        $('.showpopup').click( function( event ) {

            self.showPopup( $( this ), event );

        });

        $( '#meetings-series .serie .content-text.film a' ).click( function( event ) {

            self.showPopup( $( this ), event );

        });

        
        $( '#meetings-series .serie .meetings-wrapper .photo' ).click( function( event ) {
            
            self.showPopup( $( this ), event );

        });

        $('#popup1close').click( function() {

            self.hidePopup();

        });

    },

    showPopup: function( clickedElement, event ) {

        function convertEmbedMedia(html)
        {
            var origin = html;
            var pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/(?:.*\/)?(.+)/g;
            var pattern2 = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g;
            var pattern3 = /([-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?(?:jpg|jpeg|gif|png))/gi;

            if(pattern1.test(html)){
                var replacement = '<iframe width="490" height="240" src="//player.vimeo.com/video/$1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
                var html = html.replace(pattern1, replacement);
            }

            if(pattern2.test(html)){
                var replacement = '<iframe width="490" height="240" src="http://www.youtube.com/embed/$1" frameborder="0" allowfullscreen></iframe>';
                var html = html.replace(pattern2, replacement);
            }

            if(pattern3.test(html)){
                var replacement = '<a href="$1" target="_blank"><img class="sml" src="$1" /></a><br />';
                var html = html.replace(pattern3, replacement);
            }

            if(html == origin)
            {
                html = "Cannot parse the embed source!, please check your input.";
            }

            return html;
        }


        event.preventDefault();
        const videoElement = $( clickedElement ).parent().find( '.video' );
        if( videoElement.length == 0 )
            return true;
        const embed = convertEmbedMedia(videoElement[0].getAttribute('data-video-url'))

        this.renderPopup( embed );

    },

    renderPopup: function( html ) {

        $('#popup-window .small-film').append( html );
        
        $('#popup-window').addClass( 'visible' );

    },

    hidePopup: function() {

        $('#popup-window').removeClass( 'visible' );
        $('#popup-window .small-film' ).html('');

    }

};

$( document ).ready(function() {
   
    filmPopup._init();

});